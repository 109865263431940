import React from "react";

export const CallToAction = (props) => {
  return (
    <section className="cta bg-gradient">
      <div className="container">
        <div className="cta__content mt-n4">
          <h4 className="mb-0 mt-4">{props.text}</h4>
          <a href="/get-in-touch" className="btn btn-outline-light mt-4">
            Get in Touch
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

import React from "react";

export const Testimonials = (props) => {
  return (
    <section className="testimonials pt-0 white-strip-top">
      <div className="container">
        <h2
          className={`text-dark text-white-stroke heading-sm ${
            props.rightAlignHeader ? "text-right ml-auto" : ""
          }`}
          data-aos="fade-right"
        >
          What our customers are saying
        </h2>
        <div className="row py-5">
          <div className="col-lg-8 mx-auto">
            <div
              id="testimonialsSlider"
              className="mt-4 carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <blockquote className="blockquote text-center carousel-item active">
                  <header>
                    <img
                      className="rounded-circle mb-4"
                      src="/images/greg-white-portrait.jpg"
                      alt="Greg White portrait"
                    />
                  </header>
                  <p>
                    I've had the biggest couple of months, and its winter which
                    is usually quiet. Thanks for the new website Sixvees.
                  </p>
                  <footer className="blockquote-footer">
                    <cite className="author">Greg White</cite>,
                    <cite className="company">
                      <a
                        className="text-info"
                        href="https://windowmagic.co.nz"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Window Magic
                      </a>
                    </cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

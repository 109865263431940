import React from "react";
import { Link } from "gatsby";

export const Faqs = () => {
  const handleClick = (evt) => {
    const dataTarget = evt.target.dataset.target;
    const targetElement = document.getElementById(dataTarget);
    evt.target.classList.toggle("collapsed");
    targetElement.classList.toggle("show");
  };

  return (
    <section className="text-dark light-strip-top pt-0">
      <div className="container">
        <h2
          className="heading-sm text-right ml-auto aos-init aos-animate"
          data-aos="fade-left"
        >
          Frequently asked
          <br />
          <span className="text-white text-dark-stroke">questions</span>
        </h2>
        <div className="accordion py-5" id="faqAccordion">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse1"
                      aria-expanded="false"
                      aria-controls="collapse1"
                      onClick={handleClick}
                    >
                      How long does it take?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse1"
                  className="collapse"
                  aria-labelledby="heading1"
                  data-parent="#faqAccordion"
                >
                  <div className="card-body">
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Our <Link to="/base/">Base</Link> and{" "}
                        <Link to="/grow/">Grow</Link> websites can be live
                        within 10 business days of receiving your content. Our
                        custom <Link to="/impress/">Impress</Link> websites do
                        take a while longer, but we will let you know a timeline
                        during the quoting process.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                      onClick={handleClick}
                    >
                      Can I add more pages in future?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse2"
                  className="collapse"
                  aria-labelledby="heading2"
                  data-parent="#faqAccordion"
                  style={{}}
                >
                  <div className="card-body">
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Yes. All of our websites have been coded with the future
                        in mind. This means when you’re ready to grow we can
                        customise things to your liking.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                      onClick={handleClick}
                    >
                      Are there any ongoing costs?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse3"
                  className="collapse"
                  aria-labelledby="heading3"
                  data-parent="#faqAccordion"
                  style={{}}
                >
                  <div className="card-body">
                    <p>
                      Yes. Every website needs hosting to make it accessible to
                      the public. Our secure New Zealand based website hosting
                      is $1 per day.
                    </p>
                    <p>
                      You’ll also need a domain name if you don’t already have
                      one. These are charged annually at $45.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                      onClick={handleClick}
                    >
                      Can I see how many people view my website?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse4"
                  className="collapse"
                  aria-labelledby="heading4"
                  data-parent="#faqAccordion"
                  style={{}}
                >
                  <div className="card-body">
                    <p>
                      Your website comes with Google Analytics tracking enabled.
                      This can tell us how many people see your website, what
                      pages they look at, what device they used, their
                      demographic, and so much more. Combine this with some
                      online advertising and you can accurately track the value
                      of your advertising spend.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                      onClick={handleClick}
                    >
                      How does the process work from start to finish?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse5"
                  className="collapse"
                  aria-labelledby="heading5"
                  data-parent="#faqAccordion"
                >
                  <div className="card-body">
                    <p>
                      First, we meet with you to discuss your needs. We help you
                      choose a package and get your content organised. Once we
                      have your content we do all of the technical stuff and
                      launch your website.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 accordion__heading">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="collapse6"
                      aria-expanded="false"
                      aria-controls="collapse6"
                      onClick={handleClick}
                    >
                      Hey, cool name! What does sixvees mean?
                    </button>
                  </h4>
                </div>
                <div
                  id="collapse6"
                  className="collapse"
                  aria-labelledby="heading6"
                  data-parent="#faqAccordion"
                >
                  <div className="card-body">
                    <p>Sixvees or 6 v’s = vvvvvv = www = world wide web.</p>
                    <p>Cool huh?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;

import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Testimonials from "../components/Testimonials";
import { CallToAction } from "../components/CallToAction";
import Faqs from "../components/Faqs";
import { Helmet } from "react-helmet";

export const Grow = () => {
  return (
    <Layout>
      <Helmet>
        <title>Grow your business with this website package | Sixvees</title>
        <meta
          name="description"
          content="Showcasing your products and services with a multi-page website increases your chances of being found in a Google search."
        ></meta>
      </Helmet>
      <main>
        <div className="page-header sr-only">
          <h1>Grow</h1>
        </div>

        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <h2>Grow your business</h2>
              <p>
                Need something a bit bigger than our base website? Look no
                further than our Grow package.
              </p>
              <p>
                Showcasing your products and services with a multi-page website
                increases your chances of being found in a Google search. If you
                want to reel in more customers, you should put more hooks in the
                water.
              </p>
            </div>
          </div>
          <div className="icon-scroll" style={{ opacity: 1 }} />
        </section>

        <section className="bg-light text-dark pt-0 dark-strip-top">
          <div className="container">
            <h2 className="heading-sm mb-5 text-white-stroke">
              Grow website features
            </h2>
            <div
              className="row justify-content-between pt-4 mb-4 aos-init aos-animate"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-center"
            >
              <div className="col-lg-5 ">
                <img
                  src="/images/ice-cream-575x863.jpg"
                  alt="Ice cream in cone."
                  className="img-fluid product"
                />
              </div>
              <div className="pt-4 pt-lg-0 col-lg-6 d-flex align-items-center">
                <div>
                  <div className="lead mb-4">
                    <p>
                      Need something a little bit bigger? Look no further than
                      our Grow website package. Be found online by showcasing
                      your products and services with a multi-page website.
                    </p>
                  </div>
                  <ul className="fa-ul ml-4 mb-5 lead">
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Multiple pages means more opportunities to show up in
                      Google searches.
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Capture required customer details with an online form.
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Integrate your favourite social media platforms.
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Share your content with specific links to your products
                      and services.
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Get in depth reporting via Google Analytics. See what
                      pages are the most popular.
                    </li>
                  </ul>
                  <h3 className="mb-5">$3000</h3>

                  <Link
                    to="/get-in-touch/"
                    className="btn btn-outline-success mt-3"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faqs />
        <Testimonials />
        <CallToAction text="No sure yet? Meet with us for free coffee and advice." />
      </main>
    </Layout>
  );
};

export default Grow;
